import React, { useState } from "react";

import { Portal, ManageChoices } from "msappfwk";
import { ManageUsers, ManageRoles, ManagePrivs } from "msauth";

import Home from "../Boards/Home/Home.jsx";
import Privacy from "../Boards/Privacy/Privacy.jsx";
import Statements from "../Boards/Statements/Statements.jsx";
import Settings from "../Boards/SettingsBoard/Settings.jsx";
import PageNotFound from "./PageNotFound.jsx";

// Load in the setup fro this App
import appConfig from "../appConfig.json";

export const PortalPage = (props) => {
    const [board, setBoard] = useState(props.board || "Home");

    function displayBoard(board) {
        setBoard(board);
    }

    const components = {
        Home: Home,
        About: PageNotFound,
        Contact: PageNotFound,
        RentalTerms: PageNotFound,

        Privacy: Privacy,
        Statements: Statements,

        Settings: Settings,

        ManageUsers: ManageUsers,
        ManageRoles: ManageRoles,
        ManagePrivs: ManagePrivs,
        ChoiceBoard: ManageChoices,
        PageNotFound: PageNotFound,
    };

    const navBarComponent = (props) => {
        return (
            <React.Fragment>
                <div className="navPhone">
                    <a href="mailto:sales@prestige-car-rentals.co.uk">
                        sales@prestige-car-rentals.co.uk
                    </a>
                </div>
                <div className="navPhone">
                    <a href="tel:+441183246892">0118 324 6892</a>
                </div>
            </React.Fragment>
        );
    };

    const Board = components[board];

    return (
        <Portal
            config={appConfig}
            navBarComponent={navBarComponent}
            board={Board}
            cbDisplayBoard={displayBoard}
            noPageBand
        />
    );
};
