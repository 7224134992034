import React from "react";

import { PageHead, PageBand, SocialIcon } from "msappfwk";

import "./Home.css";

import { Hero } from "../../Components/Hero/Hero.jsx";
import { Footer } from "../../Components/Footer/Footer.jsx";

import Cars from "./Cars.jsx";

/***************************************************************************************
 *
 * Home
 *
 ***************************************************************************************/

const Comp = () => {
    let keywords = "Car Hire";
    keywords += ", Vehicle Rental";
    keywords += ", Luxury Car Hire";
    keywords += ", High-End Car Hire";
    keywords += ", Exclusive Car Rental Deals";
    keywords += ", Prestigious Car Rental Services";

    return (
        <div id="home">
            <PageHead
                title="Prestige Car Rentals | Luxury Car Hire | Premium Car Rental"
                description="Experience luxury car hire in the United Kingdom with Prestige Car Rentals. Rent premium cars for a memorable journey. Book now for a first-class driving experience."
                keywords={keywords}
                index
            ></PageHead>
            <Hero>
                <div id="herotext">
                    Luxury Rental Cars from just &pound;120 per day
                </div>
                <div id="socialicons">
                    <SocialIcon
                        network="instagram"
                        url="https://www.instagram.com/prestigecar.rentals/"
                        width="40px"
                    />
                    <SocialIcon
                        network="facebook"
                        url="https://www.facebook.com/prestige.car.rentals.berkshire"
                        width="40px"
                    />
                    <SocialIcon
                        network="linkedin"
                        url="https://linkedin.com"
                        width="40px"
                    />
                </div>
            </Hero>

            <PageBand bgColour="#333333">
                <div id="whatsapp">
                    <div>Get in touch</div>
                    <a
                        aria-label="Chat on WhatsApp"
                        href="https://wa.me/+441183246892"
                    >
                        <img
                            alt="Chat on WhatsApp"
                            src="/images/WhatsAppButtonGreenLarge.svg"
                        />
                    </a>
                </div>
            </PageBand>
            <Cars />
            <PageBand id="instagram" bgColour="#D2D2D2">
                Follow us on Instagram
                <br />
                <a href="https://www.instagram.com/Prestigecar.rentals/">
                    @prestigecar.rentals
                </a>
            </PageBand>
            <Footer />
        </div>
    );
};

export default Comp;
