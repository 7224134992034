import _ from "lodash";
import React from "react";

import { useAppContext } from "msappfwk";

import "./Privacy.css";

/***************************************************************************************
 *
 * Privacy Policy
 *
 ***************************************************************************************/

export const InfoAbout = ({ className }) => {
    const { config } = useAppContext();

    const companyShort = _.get(
        config,
        "company.shortName",
        "[Company Short Name]"
    );
    const companyLegal = _.get(
        config,
        "company.legalName",
        "[Company Legal Name]"
    );
    const regAddress = _.get(
        config,
        "company.regAddress",
        "[Company Registered Address]"
    );
    const regCountry = _.get(
        config,
        "company.regCountry",
        "[Company Registered Country]"
    );
    const regNumber = _.get(config, "company.number", "[Company Number]");

    return (
        <div className={"privacy " + (className ? className : "")}>
            <h2 class="text-uppercase fsize-xl letter-sp-neg2 line-height-089 bottom-space-l">
                About Us
            </h2>
            <div class="fsize-s light">
                {companyLegal} is incorporated in {regCountry} with registered
                number&nbsp;
                {regNumber} having its registered office at {regAddress}.
                <br />
                <br />
                All references to "we", "us" or "our" refer to {
                    companyLegal
                }{" "}
                and its affiliates and subsidiaries (&ldquo;{companyShort}
                &rdquo;).
                <br />
                <br />
                Our Privacy Policy explains (i) what information we collect and
                why we collect such information; (ii) how we use the information
                we collect; (iii) the use of cookies by {companyShort} and a
                description of {companyShort} cookies and other third party
                cookies; and (iv) what are your choices to control, access and
                modify the information we collect.
            </div>
        </div>
    );
};

export const InfoCollected = ({ className }) => {
    const { config } = useAppContext();

    const companyShort = _.get(
        config,
        "company.shortName",
        "[Company Short Name]"
    );

    return (
        <div className={"privacy " + (className ? className : "")}>
            <h2 class="text-uppercase fsize-xl letter-sp-neg2 line-height-089 bottom-space-l">
                Information that we may collect from you
            </h2>
            <div class="fsize-s light">
                <p>We may collect and process the following data about you:</p>
                <ul>
                    <p>
                        By using the contact us form, you consent to sending us:
                    </p>
                    ■ The reason for the enquiry; and
                    <br />
                    ■ Your name, phone number, email address and a message.
                    <br />
                    <br />
                    If you apply for a job position through our website, you
                    consent to sending us:
                    <br />
                    <br />
                    ■ Your name, phone number, email address and a message; and
                    <br />
                    ■ Your CV document.
                    <br />
                    <br />
                    <p>
                        The details from the contact us form are sent via email
                        to the relevant department at {companyShort};
                    </p>
                    Any information collected and stored in cookies as listed in
                    the section below.
                </ul>
            </div>
        </div>
    );
};

export const InfoUse = ({ className }) => {
    const { config } = useAppContext();

    const companyShort = _.get(
        config,
        "company.shortName",
        "[Company Short Name]"
    );

    return (
        <div className={"privacy " + (className ? className : "")}>
            <h2 class="text-uppercase fsize-xl letter-sp-neg2 line-height-089 bottom-space-l">
                How we use your information
            </h2>
            <div class="fsize-s light">
                This privacy policy tells you what to expect when {companyShort}
                collects personal information. It applies to information we
                collect about:
                <br />
                <br />
                ■ visitors to our websites; and
                <br />■ job applicants.
            </div>
        </div>
    );
};

export const InfoVisitors = ({ className }) => {
    const { config } = useAppContext();

    const companyUrl = _.get(config, "company.url", "[Company URL]");

    return (
        <div className={"privacy " + (className ? className : "")}>
            <h2 class="text-uppercase fsize-xl letter-sp-neg2 line-height-089 bottom-space-l">
                Visitors to our websites and job applicants
            </h2>
            <div class="fsize-s light">
                When someone visits {companyUrl} we collect standard internet
                log information and details of visitor behaviour patterns. We do
                this to find out things such as the number of visitors to the
                various parts of the site. We collect this information in a way
                which does not identify anyone. We do not make any attempt to
                find out the identities of those visiting either of our
                websites. We will not associate any data gathered from this site
                with any personally identifying information from any source. If
                we do want to collect personally identifiable information
                through our website, we will be up front about this. We will
                make it clear when we collect personal information and will
                explain what we intend to do with it.
            </div>
        </div>
    );
};

export const CookiesUse = ({ className }) => {
    const { config } = useAppContext();

    const companyShort = _.get(
        config,
        "company.shortName",
        "[Company Short Name]"
    );

    return (
        <div className={"privacy " + (className ? className : "")}>
            <h2 class="text-uppercase fsize-xl letter-sp-neg2 line-height-089 bottom-space-l">
                Use of cookies by {companyShort}
            </h2>
            <div class="fsize-s light">
                <p>
                    Cookies are small text files that are placed on your
                    computer by websites that you visit. They are widely used in
                    order to make websites work, or work more efficiently, as
                    well as to provide information to the owners of the site.
                </p>
                <p>
                    The cookies used by this website and their purpose are
                    identified and described in the table below. We will seek
                    your consent to use cookies the first time you visit this
                    website (or on subsequent occasions if the cookie that
                    records such acceptance is deleted). Denial of the cookies
                    used by this website may prevent parts of the website from
                    working properly.
                </p>
            </div>
        </div>
    );
};

export const CookiesCompany = ({ className }) => {
    const { config } = useAppContext();

    const companyShort = _.get(
        config,
        "company.shortName",
        "[Company Short Name]"
    );

    const companyUrl = _.get(config, "company.url", "[Company URL]");

    return (
        <div className={"privacy " + (className ? className : "")}>
            <h2 class="text-uppercase fsize-xl letter-sp-neg2 line-height-089 bottom-space-l">
                {companyShort} cookies
            </h2>
            <table className="cookie-table">
                <thead>
                    <tr>
                        <th>Cookie</th>
                        <th>Name</th>
                        <th>Purpose</th>
                        <th>Type</th>
                        <th>Expiration</th>
                        <th>Details</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>ASP.NET session ID</td>
                        <td>ASP.NET_SessionId</td>
                        <td>
                            Each user gets assigned a 120-bit random number that
                            is represented by a 20-character string and is used
                            to identify the users session on the server.
                        </td>
                        <td>Session</td>
                        <td>On session end</td>
                        <td />
                    </tr>
                    <tr>
                        <td>Google Analytics</td>
                        <td>_ga</td>
                        <td>Used to distinguish users.</td>
                        <td>Persistent</td>
                        <td>2 years</td>
                        <td>
                            <a
                                href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage#analyticsjs"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <span>Here</span>
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Google Analytics</td>
                        <td>_gat</td>
                        <td>Used to throttle request rate.</td>
                        <td>Persistent</td>
                        <td>10 minutes</td>
                        <td />
                    </tr>
                    <tr>
                        <td>{companyUrl} site cookie acceptance</td>
                        <td>{companyShort}CookiesAccepted</td>
                        <td>
                            This cookie is used to record if a user has accepted
                            the use of cookies on the {companyUrl} website.
                        </td>
                        <td>Persistent</td>
                        <td>1 year</td>
                        <td />
                    </tr>
                    <tr>
                        <td>Sitecore Analytics cookie</td>
                        <td>
                            <span class="longString">
                                SC_ANALYTICS_GLOBAL_COOKIE
                            </span>
                        </td>
                        <td>
                            To identify repeat visits from a single user,
                            Sitecore sends a persistent session cookie to the
                            web client. The name of the persistent session
                            cookie is &nbsp;
                            <span class="longString">
                                SC_ANALYTICS_GLOBAL_COOKIE
                            </span>
                            . The persistent session&nbsp;cookie expires ten
                            years after the last page requested from the
                            solution by the web client.&nbsp;
                        </td>
                        <td>Persistent</td>
                        <td>10 years</td>
                        <td>
                            <a
                                href="https://sdn.sitecore.net/upload/sitecore6/65/engagement_analytics_configuration_reference_sc65-usletter.pdf"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <span>Here</span>
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Language setting</td>
                        <td>#Lang</td>
                        <td>
                            This cookie is used to record which language the
                            user wishes to read on {companyUrl}
                        </td>
                        <td>Session</td>
                        <td>On session end</td>
                        <td />
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export const CookiesYouTube = ({ className }) => {
    return (
        <div className={"privacy " + (className ? className : "")}>
            <h2 class="text-uppercase fsize-xl letter-sp-neg2 line-height-089 bottom-space-l">
                YouTube cookies
            </h2>
            <div class="fsize-s light">
                We embed videos from our official YouTube channel using
                YouTube’s privacy-enhanced mode. This mode may set cookies on
                your computer once you click on the YouTube video player, but
                YouTube will not store personally-identifiable cookie
                information for playbacks of embedded videos using the
                privacy-enhanced mode. To find out more please visit&nbsp;
                <a
                    href="http://www.google.com/support/youtube/bin/answer.py?hl=en-GB&answer=171780"
                    target="_blank"
                    rel="noreferrer"
                >
                    <b>YouTube’s embedding videos information page</b>.
                </a>
            </div>
        </div>
    );
};

export const CookiesGoogle = ({ className }) => {
    const { config } = useAppContext();

    const companyLegal = _.get(
        config,
        "company.legalName",
        "[Company Legal Name]"
    );
    const tradeAddress = _.get(
        config,
        "company.tradeAddress",
        "[Company Trading Address]"
    );

    return (
        <div className={"privacy " + (className ? className : "")}>
            <h2 class="text-uppercase fsize-xl letter-sp-neg2 line-height-089 bottom-space-l">
                Google cookies
            </h2>
            <div class="fsize-s light">
                <p>
                    These cookies are used by Google to store user preferences
                    and information when viewing pages with Google maps on them.
                    To find out more please visit the
                    <a
                        href="http://www.google.com/intl/en_ALL/help/terms_maps.html"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <b>Google Maps Terms of Service</b>
                    </a>
                    .
                    <br />
                    <br />
                    Most web browsers allow some user control of most cookies
                    through the browser settings. To find out more about
                    cookies, including how to see what cookies have been set and
                    how to manage and delete them, visit{" "}
                    <a href="http://allaboutcookies.org/">
                        <b>www.allaboutcookies.org</b>
                    </a>
                    .<br />
                    <br />
                    To opt out of being tracked by Google Analytics across all
                    websites visit{" "}
                    <a
                        href="http://tools.google.com/dlpage/gaoptout"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <b>http://tools.google.com/dlpage/gaoptout</b>
                    </a>
                    .<br />
                    <br />
                    You have the ability to accept or decline cookies by
                    modifying the settings in your browser. However, you may not
                    be able to use all the interactive features of our site if
                    cookies are disabled.
                    <br />
                    <br />
                    There are a number of ways to manage cookies. If you use
                    different computers in different locations you will need to
                    ensure that each browser is adjusted to suit your cookie
                    preferences.
                    <br />
                    <br />
                    You can easily delete any cookies that have been installed
                    in the cookie folder of your browser. For example, if you
                    are using Microsoft Windows Explorer 8:
                    <br />
                    <br />
                    ■ Open ‘Windows Explorer’
                    <br />
                    ■ Click on ‘Tools’ menu
                    <br />
                    ■ Click ‘Delete Browsing History’
                    <br />■ Tick ‘Cookies’ and click ‘Delete’
                </p>
                <p>
                    If you are not using Microsoft Windows Explorer, then you
                    should search for “cookies” in the “Help” function for
                    information on where to find your cookie folder and the
                    controls to manage them.
                    <br />
                    <br />
                    <strong>Where we store your personal data</strong>
                    <br />
                    <br />
                    All information you provide to us is stored on our secure
                    servers. Where we have given you (or where you have chosen)
                    a password which enables you to access certain parts of our
                    site, you are responsible for keeping this password
                    confidential. We ask you not to share a password with
                    anyone.
                    <br />
                    <br />
                    Unfortunately, the transmission of information via the
                    internet is not completely secure. Although we will do our
                    best to protect your personal data, we cannot guarantee the
                    security of your data transmitted to our site; any
                    transmission is at your own risk. Once we have received your
                    information, we will use strict procedures and security
                    features to try to prevent unauthorised access.
                    <br />
                    <br />
                    <strong>Uses made of the information</strong>
                    <br />
                    <br />
                    We use information held about you in the following ways:
                    <br />
                    <br />
                    ■ To ensure that content from our site is presented in the
                    most effective manner for you and for your computer.
                    <br />
                    ■ To provide you with information, products or services that
                    you request from us or which we feel may interest you, where
                    you have consented to be contacted for such purposes.
                    <br />
                    ■ To carry out our obligations arising from any contracts
                    entered into between you and us.
                    <br />■ To allow you to participate in interactive features
                    of our service, when you choose to do so.
                </p>
                <p>
                    <strong>Disclosure of your information</strong>
                    <br />
                    <br />
                    We may disclose your personal information to any member of
                    our group, which means our subsidiaries, our ultimate
                    holding company and its subsidiaries, as defined in section
                    1159 of the UK Companies Act 2006.
                    <br />
                    <br />
                    <strong>Access to information</strong>
                    <br />
                    <br />
                    The Act gives you the right to access information held about
                    you. Your right of access can be exercised in accordance
                    with the Act. Any access request may be subject to a fee of
                    £10 to meet our costs in providing you with details of the
                    information we hold about you.
                    <br />
                    <br />
                    <strong>Changes to our privacy policy</strong>
                    <br />
                    <br />
                    Any changes we may make to our privacy policy in the future
                    will be posted on this page.
                    <br />
                    <br />
                    <strong>How to contact us</strong>
                    <br />
                    <br />
                    We respect your privacy. Any questions, comments and
                    requests regarding this privacy policy are welcomed and
                    should be addressed to info@mscsl.co.uk or by writing to: {companyLegal}, {tradeAddress}.
                </p>
            </div>
        </div>
    );
};

