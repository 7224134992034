import _ from "lodash";
import React from "react";

import { useAppContext } from "msappfwk";

import "./Footer.css";

/***************************************************************************************
 *
 * Common Footer bits
 *
 ***************************************************************************************/

export const Copyright = ({ className }) => {
    const { config } = useAppContext();

    const companyLegal = _.get(
        config,
        "company.legalName",
        "[Company Legal Name]"
    );
    const tradeAddress = _.get(
        config,
        "company.tradeAddress",
        "[Company Trading Address]"
    );

    const currentYear = new Date().getFullYear();

    return (
        <div className={"privacy " + (className ? className : "")}>
            <span>{companyLegal} | </span><span>{tradeAddress}.</span> <span>&copy; 2022-{currentYear} All Rights
            Reserved.</span>
        </div>
    );
};
