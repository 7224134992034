import React from "react";

import { PageBand } from "msappfwk";

import "./Home.css";

/***************************************************************************************
 *
 * Cars
 *
 ***************************************************************************************/

const Comp = () => {
    return (
        <PageBand bgColour="white">
            <h1>Our Cars</h1>
            <div id="carPanel">
                <Car img="Audi-A5" model="Audi A5" />
                <Car img="Jaguar-EPace" model="Jaguar E-Pace" />
                <Car img="BMW-M135i" model="BMW M135i" />
                <Car img="Golf-GTi" model="VW Golf GTi" />
                <Car img="Merc-A35" model="Mercedes A35" />
                <Car img="BMW-X3" model="BMW X3" />
                <Car img="Jaguar-EPace" model="Jaguar E-Pace" />
                <Car img="BMW-M135i" model="BMW M135i" />
                <Car img="Audi-A5" model="Audi A5" />
            </div>
        </PageBand>
    );
};

const Car = ({ img, model }) => {
    return (
        <div
            className="carPanel"
            style={{ backgroundImage: "url('/images/" + img + ".jpeg')" }}
        >
            {model && <div className="carLabel">{model}</div>}
        </div>
    );
};

export default Comp;
