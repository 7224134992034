import React from "react";

import { Routes, Route } from "react-router-dom";

import { Page404 } from "msappfwk";

import { PortalPage } from "./Pages/PortalPage.jsx";
import { AuthRequired } from "msauth";

const AppRoutes = () => {
    return (
        <Routes>
            <Route exact path="/" element={<PortalPage board="Home" />} />
            <Route
                exact
                path="/About"
                element={<PortalPage board="About" />}
            />
            <Route
                exact
                path="/Contact"
                element={<PortalPage board="Contact" />}
            />
            <Route
                exact
                path="/RentalTerms"
                element={<PortalPage board="RentalTerms" />}
            />
            <Route
                exact
                path="/PrivacyPolicy"
                element={<PortalPage board="Privacy" />}
            />
            <Route
                exact
                path="/Statements"
                element={<PortalPage board="Statements" />}
            />
            <Route
                exact
                path="/portal"
                element={
                    <AuthRequired>
                        <PortalPage />
                    </AuthRequired>
                }
            />
            <Route exact path="/login" element={<PortalPage board="Login" />} />
            <Route path="*" element={<Page404 />} />
        </Routes>
    );
};

export default AppRoutes;
