import React from "react";

import { PageBand, SocialIcon } from "msappfwk";
import { Copyright } from "../../Common/Footer/Footer";

import "./Footer.css";

export const Footer = (props) => {
    return (
        <PageBand bgColour="#222222">
            <footer id="footer">
                <div>
                    <div className="logo">
                        <img src="/images/pcr-shield2.svg" alt="PCR Logo" />
                    </div>
                    <div className="sections">
                        <div>
                            <h2>Company</h2>
                            <ul>
                                <li>
                                    <a href="/About">About</a>
                                </li>
                                <li>
                                    <a href="/Contact">Contact</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2>Policies</h2>
                            <ul>
                                <li>
                                    <a href="/RentalTerms">Rental Terms</a>
                                </li>
                                <li>
                                    <a href="/PrivacyPolicy">Privacy Policy</a>
                                </li>
                                <li>
                                    <a href="/Statements">Statements</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2>Follow Us</h2>
                            <ul>
                                <li>
                                    <SocialIcon
                                        network="instagram"
                                        url="https://www.instagram.com/prestigecar.rentals/"
                                        width="20px"
                                    />
                                    <a href="https://www.instagram.com/prestigecar.rentals/">
                                        Instagram
                                    </a>
                                </li>
                                <li>
                                    <SocialIcon
                                        network="facebook"
                                        url="https://www.facebook.com/prestige.car.rentals.berkshire"
                                        width="20px"
                                    />
                                    <a href="https://www.facebook.com/prestige.car.rentals.berkshire">
                                        Facebook
                                    </a>
                                </li>
                                <li>
                                    <SocialIcon
                                        network="linkedin"
                                        url="https://linkedin.com"
                                        width="20px"
                                    />
                                    <a href="https://linkedin.com">LinkedIn</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div>
                    <p>
                        Prestige Car Rentals is a trading name of Mainstream
                        Autos Limited.
                    </p>
                    <Copyright />
                </div>
            </footer>
        </PageBand>
    );
};
