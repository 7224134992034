import React from "react";

import { PageBand } from "msappfwk";

import {
    SlaveryEmployees,
    SlaveryFurtherSteps,
    SlaveryStance,
    SlaveryStatement,
    SlaverySupplyChain,
} from "../../Common/Slavery/Slavery.jsx";
import { Hero } from "../../Components/Hero/Hero.jsx";
import { Footer } from "../../Components/Footer/Footer.jsx";

import "./Statements.css";

/***************************************************************************************
 *
 * Slavery Statements
 *
 ***************************************************************************************/

const Comp = () => {
    return (
        <div className="statements">
            <Hero>
                <div id="herotext">Statements</div>
            </Hero>
            <PageBand>
                <SlaveryStatement className="infoBox darkGray" />
            </PageBand>

            <PageBand id="band1">
                <div className="infoBox white">
                    <h2>Our Business</h2>
                    <div>
                        <p>
                            Mainstream Autos is a car rental firm providing cars
                            for hire.
                        </p>
                        <p>
                            Mainstream Autos is based in Reading, UK and
                            provides services in the south of the country.
                        </p>
                    </div>
                </div>
                <SlaveryStance className="infoBox lightGray" />
            </PageBand>

            <PageBand id="band1">
                <SlaveryEmployees className="infoBox darkGray" />
                <SlaverySupplyChain className="infoBox white" />
            </PageBand>

            <PageBand>
                <SlaveryFurtherSteps className="infoBox lightGray" />
            </PageBand>

            <Footer />
        </div>
    );
};

export default Comp;
