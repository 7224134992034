import React from "react";

import { PageBand } from "msappfwk";
import { Footer } from "../Components/Footer/Footer.jsx";

/***************************************************************************************
 *
 * Page Not Found
 *
 ***************************************************************************************/

const Comp = () => {
    return (
        <div id="pagenotfound">
            <PageBand>
                <h1>Apologies this content is currently under review and will be available soon</h1>
            </PageBand>
            <Footer />
        </div>
    );
};

export default Comp;
