import React from 'react';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AppContextProvider, ThemeProvider, Page404 } from 'msappfwk';
import { AuthContextProvider, AuthRequired } from 'msauth';

import AppRoutes from './AppRoutes.jsx';
import { PortalPage } from "./Pages/PortalPage.jsx";

import './App.css';
import './AppLocal.css';

import appConfig from "./appConfig.json";
const App = (props) => {

    return (
        <React.StrictMode>
            <AppContextProvider config={appConfig}>
            <ThemeProvider>
            <AuthContextProvider>
                <Router>
                    <CommonRoutes />
                </Router>
            </AuthContextProvider>
            </ThemeProvider>
            </AppContextProvider>
        </React.StrictMode>
    );
}

const CommonRoutes = () => {
    return (
        <Routes>
            <Route path="/*"                        element={<AppRoutes />} />
            <Route path="/login"                    element={<PortalPage board="Login" />} />
            <Route path="/logout"                   element={<PortalPage board="Logout" />} />
            <Route path="/portal"                   element={<PortalPageWrapper />} />
            <Route path="/portal/:routeBoard/:guid" element={<PortalPageWrapper />} />
            <Route path="/portal/:routeBoard"       element={<PortalPageWrapper />} />
            <Route path="/settings"                 element={<PortalPage board="Settings" />} />
            <Route path="*" element={<Page404 />} />
        </Routes>
    );
};

const PortalPageWrapper = () => {

    return (
        <AuthRequired>
            <PortalPage />
        </AuthRequired>
    );
};

export default App;
