import React from "react";

import { 
    Card, 
    ThemeSettings, 
    CardContainer 
} from "msappfwk";

const SettingTabs = () => {

    return (
        <CardContainer>
            <Card>
                <ThemeSettings />
            </Card>
        </CardContainer>
    );
};

export default SettingTabs;
