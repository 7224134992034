import React from "react";

import { PageBand } from "msappfwk";

import {
    InfoAbout,
    InfoCollected,
    InfoUse,
    InfoVisitors,
    CookiesUse,
    CookiesCompany,
    CookiesYouTube,
    CookiesGoogle,
} from "../../Common/Privacy/Privacy.jsx";
import { Footer } from "../../Components/Footer/Footer.jsx";
import { Hero } from "../../Components/Hero/Hero.jsx";

import "./Privacy.css";

/***************************************************************************************
 *
 * Privacy Policy
 *
 ***************************************************************************************/

const Comp = () => {
    return (
        <div className="privacy">
            <Hero>
                <div id="herotext">Privacy Policy</div>
            </Hero>
            <PageBand id="band1">
                <InfoAbout className="infoBox white" />
                <InfoCollected className="infoBox lightGray" />
            </PageBand>
            <PageBand id="band1">
                <InfoUse className="infoBox lightGray" />
                <InfoVisitors className="infoBox darkGray" />
            </PageBand>

            <PageBand>
                <CookiesUse className="infoBox white" />
            </PageBand>

            <PageBand>
                <CookiesCompany className="infoBox lightGray" />
            </PageBand>

            <PageBand>
                <CookiesYouTube className="infoBox white" />
            </PageBand>

            <PageBand>
                <CookiesGoogle className="infoBox lightGray" />
            </PageBand>

            <Footer />
        </div>
    );
};

export default Comp;
